import { MinApp } from '../components/MinApp';
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'

function FeedPage() {

  const shopDomain = process.env.NEXT_PUBLIC_API_URL;
  return (
    <>

    <Head>
        <title> Claros </title>
        <link rel="icon" href="/logo.png" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    </Head>
    <PageContext.Provider value={getConfig("minapp")}>
      <MinApp className="bg-bgwhite"/>
    </PageContext.Provider>

    </>
  )
}
export default FeedPage




